import { Box, Typography, Grid, Backdrop } from '@mui/material';
import styled from "styled-components";

// CarouselItemContainer
export const CarouselItemContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  [theme.breakpoints.down('laptop')]: {
    flexDirection: 'column',
    padding: '25px 1px',
  },
}));

// ImageWrapper
export const ImageWrapper = styled(Box)({
  position: 'relative',
  width: '100%',
  height: 'auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
});

// FullscreenImageContainer
export const FullscreenImageContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  maxHeight: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  boxShadow: '25px 0 50px rgba(0, 0, 0, 0.5)',
  img: {
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'contain',
  },
  [theme.breakpoints.down('desktop')]: {
    boxShadow: 'none',
  },
}));

// FullscreenButtonWrapper
export const FullscreenButtonWrapper = styled(Box)({
  position: 'absolute',
  top: '0px',
  right: '15px',
  zIndex: 1,
});

// CarouselItemTextButtonWrapper
export const CarouselItemTextButtonWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  padding: '15px 60px',
  textAlign: 'center',
  [theme.breakpoints.down('laptop')]: {
    maxWidth: '100%',
    padding: '15px 5px',
  },
}));

// CarouselItemButtonWrapper
export const CarouselItemButtonWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  padding: '15px',
  [theme.breakpoints.down('tablet')]: {
    flexDirection: 'column',
    padding: '10px',
  },
}));

// CarouselItemButton
export const CarouselItemButton = styled(Box)({
  paddingRight: '5px',
});

// CarouselItemBottomButton
export const CarouselItemBottomButton = styled(Box)({
  padding: '5px',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
});

// CarouselItemSpan
export const CarouselItemSpan = styled(Typography)(({ theme }) => ({
  padding: '5px',
  color: '#FFFFFF',
  fontSize: '1em',
  [theme.breakpoints.down('tablet')]: {
    fontSize: '0.875em',
  },
}));


// NEW
export const CarouselItem = styled(Box) <{ isActive: boolean }>`
  position: relative;
  transition: all 0.5s ease-in-out;
  opacity: ${({ isActive }) => (isActive ? 1 : 0.12)};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
`;

export const CarouselImage = styled.img<{ isActive: boolean }>`
  width: 100%;
  height: auto;
  transform: ${({ isActive }) => (isActive ? 'scale(1)' : 'scale(0.90)')};
  justify-self: center;
  transition: all 0.5s ease-in-out;
  width: 100%;
  height: auto;
  border-radius: 10px;
`;

export const CarouselOverlay = styled(Box)`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 20px;
  border-radius: 0px 0px 10px 10px;
`;

export const CarouselFullscreenImage = styled.img`
  max-width: 90%;
  max-height: 90%;
  border-radius: 10px;
  object-fit: contain;
  height: 100%;
  z-index: 1;
`;

export const CarouselFullscreenOverlay = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  color: 'white',
  padding: '20px',
  borderRadius: '0px 0px 10px 10px',
  textAlign: 'center',
  maxWidth: '50%',
  margin: '0 auto',

  // Responsive design adjustments based on breakpoints
  [theme.breakpoints.down('desktop')]: {
    maxWidth: '50%',
  },
  [theme.breakpoints.down('laptop')]: {
    maxWidth: '70%',
  },
  [theme.breakpoints.down('tablet')]: {
    maxWidth: '100%',
  },
  [theme.breakpoints.down('mobile')]: {
    maxWidth: '100%',
  },
}));

export const CarouselFullScreenBackdrop = styled(Backdrop)`
  && {
    background-color: rgba(0, 0, 0, 0.8);
  }
`;