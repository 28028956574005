import React, { useState } from "react";
import Slider from "react-slick";
import { Box, Typography, useTheme, useMediaQuery, IconButton, Modal } from "@mui/material";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import CloseIcon from "@mui/icons-material/Close";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  CarouselFullScreenBackdrop,
  CarouselFullscreenImage,
  CarouselImage,
  CarouselItem,
  CarouselOverlay,
  CarouselFullscreenOverlay,
} from "../styles/carousel-styles";

interface ImageCarouselProps {
  imagelist: { image: string; text: string; link?: string; linkText?: string }[];
}

const ImageCarousel: React.FC<ImageCarouselProps> = ({ imagelist }) => {
  const [activeSlide, setActiveSlide] = useState(0);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [fullscreenImage, setFullscreenImage] = useState("");
  const [fullscreenText, setFullscreenText] = useState("");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("tablet")); // Adjust this based on your theme breakpoints

  const handleFullscreen = (image: string, text: string) => {
    setFullscreenImage(image);
    setFullscreenText(text);
    setIsFullscreen(true);
  };

  const settings = {
    focusOnSelect: true,
    dots: !isMobile, // Disable dots (customPaging) on mobile
    infinite: true,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
    adaptiveHeight: true,
    variableWidth: false,
    centerMode: true,
    centerPadding: "60px",
    draggable: true,
    lazyLoad: "ondemand" as const,
    beforeChange: (current: number, next: number) => setActiveSlide(next),
    customPaging: (i: number) =>
      !isMobile ? (
        <div
          style={{
            justifyContent: "center",
            width: i === activeSlide ? "30px" : "28px",
            height: i === activeSlide ? "30px" : "28px",
            borderRadius: "5px",
            overflow: "hidden",
            border:
              i === activeSlide
                ? `1px solid ${theme.palette.primary.main}`
                : "1px solid transparent",
            opacity: i === activeSlide ? 1 : 0.4,
            margin: "15px 5px",
            cursor: "pointer",
            transition: "all 0.3s ease-in-out",
          }}
        >
          <img
            src={imagelist[i].image}
            alt={`thumbnail ${i + 1}`}
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        </div>
      ) : <div />, // Return an empty div instead of null
    appendDots: (dots: React.ReactNode) =>
      !isMobile ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "15px",
          }}
        >
          {dots}
        </div>
      ) : <div />, // Return an empty div instead of null
    responsive: [
      {
        breakpoint: theme.breakpoints.values.desktop,
        settings: {
          centerPadding: "50px",
          slidesToShow: 1,
          variableWidth: true,
        },
      },
      {
        breakpoint: theme.breakpoints.values.laptop,
        settings: {
          centerPadding: "40px",
          slidesToShow: 1,
          variableWidth: true,
        },
      },
      {
        breakpoint: theme.breakpoints.values.tablet,
        settings: {
          centerPadding: "20px",
          slidesToShow: 1,
          variableWidth: false,
        },
      },
      {
        breakpoint: theme.breakpoints.values.mobile,
        settings: {
          centerPadding: "0px",
          slidesToShow: 1,
          variableWidth: false,
        },
      },
    ],
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "10vh",
        maxHeight: "100%",
        maxWidth: {
          mobile: "100vw",
          tablet: "75vh",
          laptop: "100vh",
          desktop: "100vw",
          ultraLarge: "100vw",
        },
        mx: "auto",
        px: { mobile: 3, tablet: 4, laptop: 4, desktop: 5, ultraLarge: 5 },
      }}
    >
      <Slider {...settings}>
        {imagelist.map((data, index) => (
          <CarouselItem key={index} isActive={index === activeSlide}>
            <CarouselImage
              src={data.image}
              alt="carousel item"
              isActive={index === activeSlide}
            />
            <IconButton
              aria-label="fullscreen modal"
              size="large"
              onClick={() => handleFullscreen(data.image, data.text)}
              style={{
                position: "absolute",
                top: 10,
                right: 10,
                color: "white",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              }}
            >
              <FullscreenIcon color="primary" />
            </IconButton>
            <CarouselOverlay>
              <Typography variant="body2">
                {index + 1}/{imagelist.length}
              </Typography>
              <Typography fontSize={"1.1rem"}>
                {data.text}
                {data.link && data.linkText && (
                  <Typography
                    component="span"
                    sx={{ marginLeft: 1, textDecoration: "underline", cursor: "pointer" }}
                    onClick={() => window.open(data.link, "_blank")}
                  >
                    {data.linkText}
                  </Typography>
                )}
              </Typography>
            </CarouselOverlay>
          </CarouselItem>
        ))}
      </Slider>
      <Modal
        onClick={() => setIsFullscreen(false)}
        open={isFullscreen}
        onClose={() => setIsFullscreen(false)}
        slots={{ backdrop: CarouselFullScreenBackdrop }}
        sx={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: "100vw",
            height: "90vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <IconButton
            aria-label="close fullscreen"
            size="large"
            onClick={() => setIsFullscreen(false)}
            sx={{
              position: "absolute",
              top: "70px",
              right: "5%",
              color: "white",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 10,
            }}
          >
            <CloseIcon color="primary" />
          </IconButton>
          <CarouselFullscreenImage
            src={fullscreenImage}
            alt="fullscreen image"
          />
          <CarouselFullscreenOverlay theme={theme}>
            <Typography fontSize={"1.1rem"}>{fullscreenText}</Typography>
          </CarouselFullscreenOverlay>
        </Box>
      </Modal>
    </Box>
  );
};

export default ImageCarousel;