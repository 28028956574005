import React from "react";
import { useNavigate } from "react-router-dom";
import _x20LensImageList from "../data/x20LensInstallImages";
import TheHeader from "../components/theHeader";
import ImageCarousel from "../components/carousel_two";

function X20LensInstall() {
  const navigate = useNavigate();

  return (
    <>
      <TheHeader
        headerText={"Install the X20 Lens"}
        icon={"arrow_back"}
        iconPress={(): void => navigate("/x20-setup")}
      />
      <ImageCarousel imagelist={_x20LensImageList} />
    </>
  );
}

export default X20LensInstall;
