import React from "react";
import ImageCarousel from "../components/carousel_two";
import { useNavigate } from "react-router-dom";
import _x10SoftwareImageList from "../data/m10SoftwareInstallImages";
import TheHeader from "../components/theHeader";
import { Paths } from "../enums/path.enum";

function M10SoftwareSetup() {
  const navigate = useNavigate();

  return (
    <>
      <TheHeader
        headerText={"Setup the M10"}
        icon={"arrow_back"}
        iconPress={(): void => navigate(Paths.m10SetupLegacy)}
      />
      <ImageCarousel imagelist={_x10SoftwareImageList} />
    </>
  );
}

export default M10SoftwareSetup;
