import React, { useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import {
  getUserData,
  logout,
  sendPasswordReset,
  updateUserData,
} from "../API/firebase";
import { useNavigate } from "react-router-dom";
import { UserDataTypes } from "../models/types";

import { Edit, Person, Email, Lock, Apartment } from "@mui/icons-material";
import { ListItemIcon, ListItemText } from "@mui/material";
import {
  UserInfoContainer,
  UserInfoBox,
  UserInfoList,
  UserInfoListItem,
  UserInfoIconButton,
  UserInfoButtonContainer,
  UserInfoButton,
} from "../styles/user-info-styles";
import UserInfoDialog from "./user-info-dialog";
import Divider from "@material-ui/core/Divider";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const UserInfo: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<UserDataTypes | null>(null);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [currentField, setCurrentField] = useState<{
    label: string;
    value: string;
    name: string;
    formType: string;
    dialogDescription?: string;
    buttonText?: string;
  } | null>(null);
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    "success" | "error"
  >("success");
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  useEffect(() => {
    getUserData()
      .then((data) => {
        setData(data as UserDataTypes);
        setLoading(false);
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
  }, []);

  const handleDialogOpen = (field: {
    label: string;
    value: string;
    name: string;
    formType: string;
  }) => {
    const dialogDescription =
      field.name === "password"
        ? "Please enter your email address."
        : `Please enter your new ${field.label.toLowerCase()}.`;

    const buttonText = field.name === "password" ? "Send Reset Link" : "Submit";

    setCurrentField({
      ...field,
      dialogDescription,
      buttonText,
    });
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setCurrentField(null);
  };

  const handleFormSubmit = (formData: { [key: string]: any }) => {
    setSubmitLoading(true);
    if (data) {
      if (currentField!.name === "password") {
        sendPasswordReset(data.email)
          .then(() => {
            setSubmitLoading(false);
            setSnackbarMessage("Password reset link has been sent to your email!");
            setSnackbarSeverity("success");
            setSnackbarOpen(true);
          })
          .catch((error) => {
            console.log("Error sending password reset email:", error);
            setSubmitLoading(false);
            setSnackbarMessage("Error sending password reset email.");
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
          });
      } else {
        const updatedData: UserDataTypes = {
          ...data,
          [currentField!.name]: formData[currentField!.name],
        };
        setData(updatedData);
        updateUserData(currentField!.name, formData[currentField!.name])
          .then(() => {
            setSubmitLoading(false);
            setSnackbarMessage("User data updated successfully!");
            setSnackbarSeverity("success");
            setSnackbarOpen(true);
          })
          .catch((error) => {
            console.log("Error updating user data:", error);
            setSubmitLoading(false);
            setSnackbarMessage("Error updating user data.");
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
          });
      }
    }
  };

  const handleSnackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const userInfoItems = [
    {
      icon: <Person />,
      label: "Name",
      value: data?.name ?? "",
      name: "name",
      formType: "text",
    },
    {
      icon: <Apartment />,
      label: "Company",
      value: data?.company ?? "",
      name: "company",
      formType: "text",
    },
    {
      icon: <Email />,
      label: "Email",
      value: data?.email ?? "",
      name: "email",
      editable: false,
      formType: "email",
    },
    {
      icon: <Lock />,
      label: "Password",
      value: "**********",
      name: "password",
      formType: "email",
    },
  ];

  return (
    <UserInfoContainer>
      <UserInfoBox>
        {loading ? (
          <CircularProgress />
        ) : (
          <UserInfoList>
            {userInfoItems.map((item, index) => (
              <React.Fragment key={index}>
                <UserInfoListItem
                  secondaryAction={
                    item.editable !== false && (
                      <UserInfoIconButton
                        aria-label="edit"
                        onClick={() => handleDialogOpen(item)}
                      >
                        <Edit />
                      </UserInfoIconButton>
                    )
                  }
                >
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.value} secondary={item.label} />
                </UserInfoListItem>
                {index < userInfoItems.length - 1 && (
                  <Divider variant="inset" component="li" />
                )}
              </React.Fragment>
            ))}
          </UserInfoList>
        )}

        <UserInfoButtonContainer>
          <UserInfoButton
            size="large"
            onClick={(): void => navigate("/delete-user-account")}
            variant="contained"
            color="error"
          >
            Delete account
          </UserInfoButton>

          <UserInfoButton
            size="large"
            onClick={(): Promise<void> => logout()}
            variant="outlined"
            color="secondary"
          >
            Log-out
          </UserInfoButton>
        </UserInfoButtonContainer>

        {submitLoading && <CircularProgress />}

        {currentField && (
          <UserInfoDialog
            formType={currentField.formType}
            dialogTitle={`Edit ${currentField.label}`}
            dialogDescription={currentField.dialogDescription || ""}
            buttonText={currentField.buttonText || "Submit"}
            formFieldLabel={currentField.label === "Password" ? "Email" : currentField.label}
            formFieldName={currentField.name}
            onSubmit={handleFormSubmit}
            open={dialogOpen}
            handleClose={handleDialogClose}
          />
        )}

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </UserInfoBox>
    </UserInfoContainer>
  );
};

export default UserInfo;
