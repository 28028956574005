import React from "react";
import ImageCarousel from "../components/carousel_two";
import _x10HardwareImageList from "../data/m10HardwareInstallImages";
import { useNavigate } from "react-router-dom";
import TheHeader from "../components/theHeader";
import { Paths } from "../enums/path.enum";

function M10HardwareSetup() {
  const navigate = useNavigate();

  return (
    <>
      <TheHeader
        headerText={"Install the M10"}
        icon={"arrow_back"}
        iconPress={(): void => navigate(Paths.m10SetupLegacy)}
      />
      <ImageCarousel imagelist={_x10HardwareImageList} />
    </>
  );
}

export default M10HardwareSetup;
