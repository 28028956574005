import React, { //useEffect 
  } from "react";
//import { InstallStateStore } from "../store/store";
import ImageCarousel from "../components/carousel_two";
import { useNavigate } from "react-router-dom";
import TheHeader from "../components/theHeader";
import _s1ImageList from "../data/s1InstallImages";
//import { Index } from "firebase/firestore";

function S1Setup() {
  /* fo future use
  const [updateS1HardwareState, s1HardwareState] = InstallStateStore(
    (state) => [state.UpdateS1HardwareState, state.s1HardwareState]
  );
  

  useEffect(() => {
    updateS1HardwareState(false);
  }, [updateS1HardwareState]);
  

  

  function slideChange(swiper: Index) {
    if (swiper.activeIndex === _s1ImageList.length - 1) {
      updateS1HardwareState(true);
    } else {
      updateS1HardwareState(false);
    }
  }

  function buttonPress() {
    navigate("/");
  }
    */

  const navigate = useNavigate();

  return (
    <>
      <TheHeader
        headerText={"Install the S1"}
        icon={"home"}
        iconPress={(): void => navigate("/")}
      />
      <ImageCarousel
        imagelist={_s1ImageList}
      />
    </>
  );
}

export default S1Setup;

/*
<ImageCarousel
        imagelist={_s1ImageList}
        onSlideChange={(e) => slideChange(e)}
        showRightButton={s1HardwareState}
        rightButtonPress={() => buttonPress()}
        rightButtonTitle="Done"
        link="/"
        linkText="test"
      />
*/