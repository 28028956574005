// StyledComponents.js
import styled from 'styled-components';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

export const UserInfoContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: calc(100vh - 300px);
  overflow-y: auto;
  margin-top: 30px;
`;

export const UserInfoBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;
`;

export const UserInfoList = styled(List)`
  width: 100%;
  max-width: 360px;
  color: #ffffff;
`;

export const UserInfoListItem = styled(ListItem)`
  & .MuiListItemIcon-root {
    color: #ffffff;
  }
  & .MuiListItemText-primary,
  & .MuiListItemText-secondary {
    color: #ffffff;
  }
`;

export const UserInfoIconButton = styled(IconButton)`
  &.MuiButtonBase-root {
    color: #ffffff;
  }
`;

export const UserInfoButtonContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  max-width: 360px;
`;

export const UserInfoButton = styled(Button)`
  margin: 10px;
`;
