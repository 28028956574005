import { initializeApp } from "firebase/app";
import { ProductItemTypes, UserDataTypes, UserProductTypes } from "../models/types"

import {
    getAuth,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    sendPasswordResetEmail,
    signOut,
    sendEmailVerification,
    Auth,
    onAuthStateChanged
} from "firebase/auth";
import {
    getFirestore,
    collection,
    addDoc,
    doc,
    getDoc,
    setDoc, query, where, getDocs, Firestore, Timestamp, updateDoc, deleteDoc,
    arrayUnion
} from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyC2bQ9IebZ1KuYEBjrnL5zNdDxeOrZWbFg",
    authDomain: "codeus-production.firebaseapp.com",
    projectId: "codeus-production",
    storageBucket: "codeus-production.appspot.com",
    messagingSenderId: "635116471265",
    appId: "1:635116471265:web:30ea23fc097d57f75a48a0",
    measurementId: "G-BRQ3QHV6LR"
};

const app = initializeApp(firebaseConfig);
const auth: Auth = getAuth(app);
const db: Firestore = getFirestore(app);

//const [updateUserState] = UserLoginStore((state) => [state.UpdateUserLoginState]);

// sign-in
const logInWithEmailAndPassword = async (email: string, password: string) => {
    try {
        await signInWithEmailAndPassword(auth, email, password);
    } catch (err: any) {
        throw err.code
    }
};

// save user data
const saveUserData = async (userData: UserDataTypes) => {
    const user = auth.currentUser;
    if (user) {
        const docRef = doc(db, "users", user.uid);
        await setDoc(docRef, userData);
        console.log("User data saved successfully!");
    } else {
        console.log("No user is signed in.");
    }
};


// sign-up
const registerWithEmailAndPassword = async (name: string, company: string, email: string, password: string) => {
    try {
        const res = await createUserWithEmailAndPassword(auth, email, password);
        const user = res.user;
        /*
        const actionCodeSettings = {
            url: 'https://www.artomeinstaller.com/?email={$user@example.com}',
            iOS: {
                bundleId: 'com.artome-installer.ios'
            },
            android: {
                packageName: 'com.artome-installer.android',
                installApp: true,
                minimumVersion: '12'
            }
        }
        await sendEmailVerification(user, actionCodeSettings);
        */
        await saveUserData(
            {
                id: user.uid,
                name,
                company,
                email,
                register_method: "artomeinstaller.com"
            }
        );
    } catch (err: any) {
        throw err.code
    }
};

// send email for verification
const emailVerification = async () => {
    onAuthStateChanged(auth, async (user) => {
        if (user) {
            const actionCodeSettings = {
                url: 'https://www.artomeinstaller.com/?email={$user@example.com}',
                iOS: {
                    bundleId: 'com.artome-installer.ios'
                },
                android: {
                    packageName: 'com.artome-installer.android',
                    installApp: true,
                    minimumVersion: '12'
                }
            }
            try {
                await sendEmailVerification(user, actionCodeSettings)
                    .then(() => {
                        // Email verification sent
                        alert("check your email");
                    });
            } catch (err: any) {
                throw err.code;
            }
        } else {
            // User is signed out
            // ...
        }
    });
};

// send user password reset
const sendPasswordReset = async (email: string): Promise<void> => {
    try {
        await sendPasswordResetEmail(auth, email);
        console.log("Check your email for the password reset link!");
    } catch (error) {
        const errorCode = (error as { code: string }).code;
        let errorMessage: string;

        switch (errorCode) {
            case 'auth/user-not-found':
                errorMessage = 'No user found with this email address.';
                break;
            case 'auth/invalid-email':
                errorMessage = 'The email address is not valid.';
                break;
            case 'auth/too-many-requests':
                errorMessage = 'Too many requests. Please try again later.';
                break;
            default:
                errorMessage = 'An error occurred. Please try again.';
                break;
        }

        console.error("Error sending password reset email:", errorMessage);
    }
};

// log-out user
const logout = async () => {
    await signOut(auth);
};

// get user data
const getUserData = async () => {
    const user = auth.currentUser;
    if (user) {
        const docRef = doc(db, "users", user.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            return docSnap.data() as UserDataTypes;
        } else {
            // undefined
            console.log("No user data found!");
            return null;
        }
    } else {
        console.log("No user is signed in.");
        return null;
    }
};

// get user products
const getUserProductsData = async () => {
    const user = auth.currentUser;

    if (user) {
        const q = query(collection(db, "web_installer"), where("uid", "==", user.uid));
        const querySnapshot = await getDocs(q);
        const products: UserProductTypes[] = [];
        querySnapshot.forEach((doc) => {
            const data = doc.data();
            const product: UserProductTypes = {
                ...data,
                setup_date: (data.setup_date as Timestamp).toDate().toLocaleString(undefined, {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit'
                }) // Convert Timestamp to Date and then to string
            } as UserProductTypes;
            products.push(product);
        });
        return products;
    } else {
        throw new Error("No user is currently signed in.");
    }
};

// get user product item
const getUserProductItemData = async (artomeSerialNo: string) => {
    const user = auth.currentUser;

    if (user) {
        const q = query(collection(db, "web_installer"), where("uid", "==", user.uid), where("artome_serial_no", "==", artomeSerialNo));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
            const doc = querySnapshot.docs[0];
            const data = doc.data();
            const product: ProductItemTypes = {
                ...data,
                setup_date: (data.setup_date as Timestamp).toDate().toLocaleString(undefined, {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                }) // Convert Timestamp to Date and then to string
            } as ProductItemTypes;
            return product;
        } else {
            throw new Error("No product found for the current user with the given artome_serial_no.");
        }
    } else {
        throw new Error("No user is currently signed in.");
    }
};

// create & update products registration records
const registrationRecords = async (epsonSerNo: string, artomeSerNo: string, product: string, owner: string) => {
    try {
        onAuthStateChanged(auth, async (user) => {
            if (user) {
                const q = query(collection(db, "web_installer"), where("artome_serial_no", "==", artomeSerNo));
                const querySnapshot = await getDocs(q);
                if (!querySnapshot.empty) {
                    const docToUpdate = querySnapshot.docs[0];
                    const docData = docToUpdate.data();
                    const previousOwners = docData.previous_owners || [];
                    if (docData.product_owner) {
                        previousOwners.push({
                            owner: docData.product_owner,
                            date: docData.re_setup_date || docData.setup_date,
                        });
                    }

                    const updatedData = {
                        artome_product: product.toUpperCase(),
                        product_owner: owner,
                        language: navigator.language,
                        epson_serial_no: epsonSerNo,
                        previous_owners: previousOwners,
                        re_setup_date: new Date(),
                        uid: user.uid
                    };

                    console.log("Data to be saved:", updatedData);

                    await updateDoc(doc(db, "web_installer", docToUpdate.id), updatedData);
                } else {
                    const newData = {
                        artome_product: product.toUpperCase(),
                        product_owner: owner,
                        artome_serial_no: artomeSerNo.toUpperCase(),
                        language: navigator.language,
                        epson_serial_no: epsonSerNo,
                        previous_owners: [],
                        setup_date: new Date(),
                        uid: user.uid
                    };

                    console.log("Data to be added:", newData);

                    await addDoc(collection(db, "web_installer"), newData);
                }
            } else {
                // User is signed out
                // Handle sign-out scenario if necessary
                console.warn("User is signed out.");
            }
        });
    } catch (err) {
        console.error(err);
    }
};


// create & update the S1 provisioning records
const s1ProvisioningRecords = async (epsonSerNo: string, artomeSerNo: string, product: string, productSetupCompleted: boolean, SoftwareSetupCompleted: boolean) => {
    try {
        onAuthStateChanged(auth, async (user) => {
            if (user) {
                // Check if a document with the same artome_serial_no already exists
                const q = query(collection(db, "web_installer"), where("artome_serial_no", "==", artomeSerNo));
                const querySnapshot = await getDocs(q);
                if (!querySnapshot.empty) {
                    // A document with the same artome_serial_no already exists, update it
                    const docToUpdate = querySnapshot.docs[0];
                    await updateDoc(doc(db, "web_installer", docToUpdate.id), {
                        artome_product: product,
                        language: navigator.language,
                        epson_serial_no: epsonSerNo,
                        //ip_address: "0.0.0.0",
                        //location: [lat: 0, long: 0],
                        product_setup_completed: productSetupCompleted,
                        re_setup_date: new Date(),
                        software_setup_completed: SoftwareSetupCompleted,
                        uid: user.uid
                    });
                } else {
                    // No document with the same artome_serial_no exists, proceed to add
                    await addDoc(collection(db, "web_installer"), {
                        artome_product: product,
                        artome_serial_no: artomeSerNo,
                        language: navigator.language,
                        epson_serial_no: epsonSerNo,
                        //ip_address: "0.0.0.0",
                        //location: [lat: 0, long: 0],
                        product_setup_completed: productSetupCompleted,
                        setup_date: new Date(),
                        software_setup_completed: SoftwareSetupCompleted,
                        uid: user.uid
                    });
                }
            } else {
                // User is signed out
                // ...
            }
        });
    } catch (err: any) {
        console.error(err);
    }
};

// create & update the M10 provisioning records
const m10ProvisioningRecords = async (epsonSerNo: string, artomeSerNo: string, product: string, productSetupCompleted: boolean, SoftwareSetupCompleted: boolean) => {
    try {
        onAuthStateChanged(auth, async (user) => {
            if (user) {
                // Check if a document with the same artome_serial_no already exists
                const q = query(collection(db, "web_installer"), where("artome_serial_no", "==", artomeSerNo));
                const querySnapshot = await getDocs(q);
                if (!querySnapshot.empty) {
                    // A document with the same artome_serial_no already exists, update it
                    const docToUpdate = querySnapshot.docs[0];
                    await updateDoc(doc(db, "web_installer", docToUpdate.id), {
                        artome_product: product,
                        language: navigator.language,
                        epson_serial_no: epsonSerNo,
                        //ip_address: "0.0.0.0",
                        //location: [lat: 0, long: 0],
                        product_setup_completed: productSetupCompleted,
                        re_setup_date: new Date(),
                        software_setup_completed: SoftwareSetupCompleted,
                        uid: user.uid
                    });
                } else {
                    // No document with the same artome_serial_no exists, proceed to add
                    await addDoc(collection(db, "web_installer"), {
                        artome_product: product,
                        artome_serial_no: artomeSerNo,
                        language: navigator.language,
                        epson_serial_no: epsonSerNo,
                        //ip_address: "0.0.0.0",
                        //location: [lat: 0, long: 0],
                        product_setup_completed: productSetupCompleted,
                        setup_date: new Date(),
                        software_setup_completed: SoftwareSetupCompleted,
                        uid: user.uid
                    });
                }
            } else {
                // User is signed out
                // ...
            }
        });
    } catch (err: any) {
        console.error(err);
    }
};

// create & update the X20 provisioning records
const x20ProvisioningRecords = async (epsonSerNo: string, artomeSerNo: string, lens: string, product: string, lensSetupCompleted: boolean, productSetupCompleted: boolean, SoftwareSetupCompleted: boolean) => {
    try {
        onAuthStateChanged(auth, async (user) => {
            if (user) {
                // Check if a document with the same artome_serial_no already exists
                const q = query(collection(db, "web_installer"), where("artome_serial_no", "==", artomeSerNo));
                const querySnapshot = await getDocs(q);
                if (!querySnapshot.empty) {
                    // A document with the same artome_serial_no already exists, update it including the uid
                    const docToUpdate = querySnapshot.docs[0];
                    await updateDoc(doc(db, "web_installer", docToUpdate.id), {
                        artome_product: product,
                        language: navigator.language,
                        epson_serial_no: epsonSerNo,
                        lens_setup_completed: lensSetupCompleted,
                        product_setup_completed: productSetupCompleted,
                        re_setup_date: new Date(),
                        software_setup_completed: SoftwareSetupCompleted,
                        lens: lens,
                        uid: user.uid // Update the uid
                    });
                } else {
                    // No document with the same artome_serial_no exists, proceed to add
                    await addDoc(collection(db, "web_installer"), {
                        artome_product: product,
                        artome_serial_no: artomeSerNo,
                        language: navigator.language,
                        epson_serial_no: epsonSerNo,
                        lens_setup_completed: lensSetupCompleted,
                        product_setup_completed: productSetupCompleted,
                        setup_date: new Date(),
                        software_setup_completed: SoftwareSetupCompleted,
                        lens: lens,
                        uid: user.uid
                    });
                }
            } else {
                // User is signed out
                // ...
            }
        });
    } catch (err: any) {
        console.error(err);
    }
};

// Remove the product
const removeProduct = async (artomeSerNo: string) => {
    try {
        onAuthStateChanged(auth, async (user) => {
            if (user) {
                // Check if a document with the same artome_serial_no exists
                const q = query(collection(db, "web_installer"), where("artome_serial_no", "==", artomeSerNo));
                const querySnapshot = await getDocs(q);
                if (!querySnapshot.empty) {
                    // A document with the same artome_serial_no exists, delete it
                    const docToDelete = querySnapshot.docs[0];
                    await deleteDoc(doc(db, "web_installer", docToDelete.id));
                } else {
                    console.log("No document found with the specified artome_serial_no");
                }
            } else {
                // User is signed out
                // Handle the case where the user is not authenticated
            }
        });
    } catch (err) {
        console.error(err);
    }
};

// Update user data
const updateUserData = async (field: string, value: string) => {
    const user = auth.currentUser;
    if (user) {
        const docRef = doc(db, "users", user.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            const userData = docSnap.data();
            const oldValue = userData[field] || null;

            const updates: { [key: string]: any } = {
                [field]: value,
            };

            if (oldValue) {
                const oldFieldArray = `${field}_history`;
                updates[oldFieldArray] = arrayUnion(oldValue);
            }

            await updateDoc(docRef, updates);
            console.log("User data saved successfully!");
        } else {
            console.log("No such document!");
        }
    } else {
        console.log("No user is signed in.");
    }
};

export {
    auth,
    db,
    logInWithEmailAndPassword,
    registerWithEmailAndPassword,
    sendPasswordReset,
    logout,
    emailVerification,
    x20ProvisioningRecords,
    m10ProvisioningRecords,
    s1ProvisioningRecords,
    getUserData,
    getUserProductsData,
    getUserProductItemData,
    removeProduct,
    updateUserData,
    registrationRecords
};