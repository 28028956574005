import { createTheme } from "@mui/material/styles";

// Augment the palette to include custom colors
declare module "@mui/material/styles" {
  interface Palette {
    artome: Palette["primary"];
    dark: Palette["primary"];
  }

  interface PaletteOptions {
    artome?: PaletteOptions["primary"];
    dark?: PaletteOptions["primary"];
  }
}

// Update the Button's color options to include custom colors
declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    artome: true;
    dark: true;
  }
}

// Update the IconButton's color options to include custom colors
declare module "@mui/material/IconButton" {
  interface IconButtonPropsColorOverrides {
    artome: true;
    dark: true;
  }
}

// Define custom breakpoints
declare module "@mui/system" {
  interface BreakpointOverrides {
    xs: false;
    sm: false;
    md: false;
    lg: false;
    xl: false;
    laptop: true;
    tablet: true;
    mobile: true;
    desktop: true;
    ultraLarge: true;
  }
}

const theme = createTheme({
  breakpoints: {
    values: {
      ultraLarge: 3000,
      desktop: 1280,
      laptop: 1024,
      tablet: 640,
      mobile: 0,
    },
  },
  components: {
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          color: "#FFFFFF",
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          color: "#FFFFFF",
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: 25,
          backgroundColor: "#212121",
          color: "#FFFFFF",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 25,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: 25,
          color: "#FFFFFF",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          color: "#FFFFFF",
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          color: "#FFFFFF",
          backgroundColor: "#FFFFFF",
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          color: "#FFFFFF",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "#FFFFFF",
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: "#FFFFFF",
        },
      },
    },
  },
  palette: {
    artome: {
      main: "#ECAA1D",
      light: "#ffffff",
      dark: "#000000",
    },
    primary: {
      main: "#ECAA1D",
    },
    secondary: {
      main: "#FFFFFF",
    },
    dark: {
      main: "#000000",
    },
  },
});

export default theme;
